.contact-icon {
    font-size: 1.5em !important;
}

.contact-button-icon {
    font-size: 2.5em !important;
}

.contact-link-style {
    width: 100%;
    cursor: pointer;
    text-decoration: none;
    color: #ae1496 !important;
}

.contact-link-style:hover {
    text-decoration: none !important;
}