@import url(https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap);
.top-link-style {
    margin: 10px !important;
    color: #7b777b !important;
    cursor: pointer;
    text-decoration: none !important;
}

@font-face {
    font-family: 'Fjalla One', sans-serif;
}

.text-style {
    font-family: 'Fjalla One';
    font-size: 2em;
}

.menuIcon {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.5s, visibility 0.5s;
}

.menuElement:hover .menuIcon {
    visibility: visible;
    opacity: 0.7;
    transition: opacity 0.5s, visibility 0.5s;
}
@font-face {
    font-family: 'Fjalla One', sans-serif;
}

.languageMenu {
    background: #ffffff00;
}

.MuiPaper-root {
    background: #ffffff00 !important;
}

.MuiMenuItem-root {
    color: #7b777b !important;
    font-family: 'Fjalla One' !important;
    font-size: 2em !important;
}
.drawer-container {
    background: linear-gradient(to right, #353535f1, #363636fa);;
    width: 100% !important;
    height: 100% !important;
}

.drawer-close-container {
    max-width: 20px;
    height: 100% !important;
    display: flex;
    align-items: center;
}

.drawer-main-container {
    max-width: 300px;
    float: right;
}

.MuiDrawer-paperAnchorRight {
    width: 320px !important;
}

.mobile-menu-open-icon {
    font-size: 1.5em !important;
}

.center {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

.contact-mobile-icon {
    font-size: 1.6em !important;
}

.bottom-container {
    position: fixed;
    width: 252px;
    bottom: 20px;
}


.element-on-video{
    position: absolute;
    top: 50%;
    left: 16px;
}

@font-face{
    src: local('DancingScript-VariableFont_wght'), url(/static/media/DancingScript-VariableFont_wght.d618761a.ttf) format('woff');
    font-family: 'DancingScript-VariableFont_wght';
}

.Typical-text-style{
    font-family: DancingScript-VariableFont_wght;
    font-size: 2em;
    font-weight: bold;
    color: #ff1edc;
    opacity: 0.8;
}
/* .image-zoom-onhover:hover {
    transform: scale(1.1);
} */



/* 
.img-wrapper {
    width: 400px;
    height: 400px;
    overflow: hidden; 
} */
/* 
.open-image {
    position: fixed;
    z-index: 10;
    top: 10%;
    left: 10%;
    width: 80%;
    height: auto;
    transition: 0.8s;
} */
.contact-icon {
    font-size: 1.5em !important;
}

.contact-button-icon {
    font-size: 2.5em !important;
}

.contact-link-style {
    width: 100%;
    cursor: pointer;
    text-decoration: none;
    color: #ae1496 !important;
}

.contact-link-style:hover {
    text-decoration: none !important;
}
.footerMain {
    background-color: black;
}
.go-top {
    cursor: pointer;
    position: fixed;
    right: 30px;
    bottom: 30px;
}

.go-top-icon{
    color: #ff1edc; 
    font-size: 3em !important;
}
body { 
    position: absolute;
    left: 0px;
    right: 0px;
    padding-top: 0;
    padding-right: 0px !important;
    padding-left: 0px;
    padding-bottom: 0px;
    overflow-x: hidden;
    overflow-y: auto !important;
}

.homeMain {
    flex: 1 1;
    background-color: black;
}
