body { 
    position: absolute;
    left: 0px;
    right: 0px;
    padding-top: 0;
    padding-right: 0px !important;
    padding-left: 0px;
    padding-bottom: 0px;
    overflow-x: hidden;
    overflow-y: auto !important;
}

.homeMain {
    flex: 1;
    background-color: black;
}