@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap');

@font-face {
    font-family: 'Fjalla One', sans-serif;
}

.languageMenu {
    background: #ffffff00;
}

.MuiPaper-root {
    background: #ffffff00 !important;
}

.MuiMenuItem-root {
    color: #7b777b !important;
    font-family: 'Fjalla One' !important;
    font-size: 2em !important;
}