.drawer-container {
    background: linear-gradient(to right, #353535f1, #363636fa);;
    width: 100% !important;
    height: 100% !important;
}

.drawer-close-container {
    max-width: 20px;
    height: 100% !important;
    display: flex;
    align-items: center;
}

.drawer-main-container {
    max-width: 300px;
    float: right;
}

.MuiDrawer-paperAnchorRight {
    width: 320px !important;
}

.mobile-menu-open-icon {
    font-size: 1.5em !important;
}

.center {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

.contact-mobile-icon {
    font-size: 1.6em !important;
}

.bottom-container {
    position: fixed;
    width: 252px;
    bottom: 20px;
}