

.element-on-video{
    position: absolute;
    top: 50%;
    left: 16px;
}

@font-face{
    src: local('DancingScript-VariableFont_wght'), url(../media/fonts/DancingScript-VariableFont_wght.ttf) format('woff');
    font-family: 'DancingScript-VariableFont_wght';
}

.Typical-text-style{
    font-family: DancingScript-VariableFont_wght;
    font-size: 2em;
    font-weight: bold;
    color: #ff1edc;
    opacity: 0.8;
}