@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap');

.top-link-style {
    margin: 10px !important;
    color: #7b777b !important;
    cursor: pointer;
    text-decoration: none !important;
}

@font-face {
    font-family: 'Fjalla One', sans-serif;
}

.text-style {
    font-family: 'Fjalla One';
    font-size: 2em;
}

.menuIcon {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.5s, visibility 0.5s;
}

.menuElement:hover .menuIcon {
    visibility: visible;
    opacity: 0.7;
    transition: opacity 0.5s, visibility 0.5s;
}