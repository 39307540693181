.go-top {
    cursor: pointer;
    position: fixed;
    right: 30px;
    bottom: 30px;
}

.go-top-icon{
    color: #ff1edc; 
    font-size: 3em !important;
}